import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ADMIN_OFFERS, ADMIN_OFFERS_MANAGE, ADMIN_OFFER_PRODUCTS_MANAGE } from 'Consts/routes';
import { ACTIVITY_LOGS_DATA_TYPE_OFFER_PRODUCT } from 'Consts/activityLogs';

import { withVariables } from 'Utils/string';

import StyledComponent from 'Components/core/StyledComponent';
import PageContent from 'Components/layout/panel/PageContent';
import Page from 'Components/layout/panel/Page';
import TabNavigation from 'Components/layout/panel/TabNavigation';
import Editor from 'Components/admin/offerProducts/Editor';
import { TAB_OFFERS } from 'Components/pages/admin/Offers/component';
import { TAB_PRODUCTS_LIST } from 'Components/pages/admin/OffersManage/component';
import LogsList from 'Components/admin/activityLogs/List';

export const TAB_DATA = 'data';
export const TAB_LOGS = 'logs';

export default class AdminOfferProductsManage extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        match: PropTypes.shape({
            params: PropTypes.shape({
                id: PropTypes.string.isRequired,
            }).isRequired,
        }).isRequired,
        actions: PropTypes.shape({
            single: PropTypes.func.isRequired,
        }).isRequired,
        offerProduct: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            isLoaded: PropTypes.bool.isRequired,
            data: PropTypes.object,
        }),
    };
    static defaultProps = {};
    state = {};

    componentDidMount = () => {
        this.loadData();
    }

    componentDidUpdate = prevProps => {
        const { match } = this.props;
        const { params } = match;

        if (prevProps.match.params.id !== params.id) {
            this.loadData();
        }
    }

    loadData = () => {
        const { actions, match } = this.props;
        const { params } = match;

        actions.single({ id: params.id });
    }

    render() {
        const { offerProduct, location, history } = this.props;

        return (
            <StyledComponent
                className="admin-offer-products-manage"
                styles={require('./styles')}
            >
                <Page
                    elementMode={true}
                    data={offerProduct}
                >
                    {offerProduct && offerProduct.data && (
                        <PageContent   
                            breadcrumbs={[{
                                to: withVariables(ADMIN_OFFERS.path, {}, { tab: TAB_OFFERS }),
                                label: 'Oferty',
                            }, {
                                to: withVariables(ADMIN_OFFERS_MANAGE.path, { id: offerProduct.data.offer.id }, { tab: TAB_PRODUCTS_LIST }),
                                label: `Edytuj ofertę: ${ offerProduct.data.offer.name }`,
                            }, {
                                to: withVariables(ADMIN_OFFER_PRODUCTS_MANAGE.path, { id: offerProduct.data.id }),
                                label: 'Edytuj produkt zamówienia',
                            }]}
                        >
                            <TabNavigation
                                location={location}
                                history={history}
                                headline="Edytuj produkt zamówienia"
                                subHeadline={offerProduct.data.name}
                                tabs={[{
                                    key: TAB_DATA,
                                    label: 'Dane',
                                    children: (
                                        <Editor
                                            location={location}
                                            history={history}
                                            data={offerProduct.data}
                                        />
                                    ),
                                }, {
                                    visible: true,
                                    key: TAB_LOGS,
                                    label: 'Logi',
                                    children: (
                                        <LogsList 
                                            location={location}
                                            history={history}
                                            predefinedQuery={{
                                                dataType: ACTIVITY_LOGS_DATA_TYPE_OFFER_PRODUCT,
                                                dataId: offerProduct.data.id || undefined,
                                            }}
                                            onMapFilters={() => []}
                                        />
                                    ),
                                }]}
                            />
                        </PageContent>  
                    )}
                </Page>
            </StyledComponent>
        );
    }
}