import { connect } from 'react-redux';
import Component from './component';

import {
    update,
    create,
    destroy,
    list as listOfferProducts,
} from 'Redux/modules/admin/offerProducts/actions';

import {
    list as listProducts,
} from 'Redux/modules/admin/products/actions';

import {
    list as listOffers,
} from 'Redux/modules/admin/offers/actions';

export default connect(
    state => ({}),
    dispatch => ({
        actions: {
            update: dispatch(update),
            create: dispatch(create),
            destroy: dispatch(destroy),
            listProducts: dispatch(listProducts),
            listOffers: dispatch(listOffers),
            listOfferProducts: dispatch(listOfferProducts),
        },
    }),
)(Component);